
const showSearch = () => { 
    const searchBox = document.getElementById('custom--search')
    searchBox.classList.add('active')
}
const closeSearch = () => { 
    const searchBox = document.getElementById('custom--search')
    searchBox.classList.remove('active')
}

window.addEventListener('keyup', e => e.key === "Escape" && closeSearch())




const toggleNav = () => { 
    document.body.classList.toggle('nav--open')
}

const closeSubNav = () => { 
    const subMenu = document.querySelectorAll('.sub-menu')
    subMenu.forEach(menu => { 
        menu.classList.remove('active')
    })
}


const openSubNav = () => { 
    const hasChildren = document.querySelectorAll('.menu-item-has-children > a')
    const subMenu = document.querySelectorAll('.sub-menu')
    let back = 'zurück'

    subMenu.forEach(menu => { 
        menu.insertAdjacentHTML('afterbegin', `<div class="back--link" onclick="closeSubNav()"> < ${back} </div>`)
    })

    hasChildren.forEach(child => { 
        
        child.addEventListener('click', e => { 
            if (window.innerWidth < 1270) {
                e.preventDefault()                
            }
            child.parentNode.querySelector('.sub-menu').classList.add('active')
        })
    })
}

const mainNavItems = document.querySelectorAll('#menu-main li:not(.menu-item-has-children) a')

mainNavItems.forEach(item => {
    item.addEventListener('click', toggleNav)
})


openSubNav()



